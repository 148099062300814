import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contactForm"
import Hero from "../../components/hero"
import Services from "../../components/services2"
import Content from "../../components/content"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const ServicesPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * Services Page Component
 * This component is used to generate the Services page
 *
 * @param {Object} props
 */
const MandatosPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Mandatos" description="" />

      <Hero
        type="simple"
        tagline="Estos son los principales servicios jurídicos que le ofrecemos"
        title="Mandatos"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline="El Fundamento legal de este contrato lo encontramos en el Libro Quinto art. 1686
        del Código Civil de Guatemala."
        title="Mandatos para guatemaltecos en Estados Unidos"
      >
        <p>
        Y lo debemos entender como aquel contrato mediante el cual una persona llamada Mandante
        “Es decir la persona que se encuentra en Estados Unidos” encomienda a otra llamada
        Mandatario “la persona de confianza que usted nombra y se   encuentra en Guatemala”
        facultades para realizar ciertos actos o negocios jurídicos en representación del Mandante
        en la Republica de Guatemala.
        </p>
        <p>
        La ley de Guatemala clasifica los Mandatos en Generales, Especiales y Judiciales,
        de esa cuenta existen varios tipos de mandatos que dependiendo del negocio o acto
        jurídico que usted necesite   realizar en Guatemala así le otorgara facultades
        suficientes a su representante legal nombrado, para que este las realice en nombre
        y representación suya en dicho país.
        </p>
        <p>
      <ol>
       <li><strong>Mandatos para vender y comprar propiedades, vehículos y cualquier otro bien.<br>
        </br></strong></li>
	     <li><strong>Mandatos para contraer matrimonio, para divorciarse.<br>
        </br></strong></li>
	      <li><strong>Mandatos para realizar trámites de reconocimiento de hijos, rectificaciones,
        reposiciones  de partidas de nacimiento, 	cambios de nombre, identificación de personas, 
        asentar nacimientos extemporáneos en el Registro Nacional de las Personas de 	la Republica
        de Guatemala. RENAP.<br>
        </br></strong></li>
	      <li><strong>Mandatos para abrir, cerrar, activar,  cuentas  bancarias, retirar dinero de las mismas,
        total o parcialmente, solicitar 	usuario de banca en línea para que el titular de la cuenta
        pueda realizar sus transacciones bancarias via internet desde los 	Estados Unidos.<br>
        </br></strong></li> 
	      <li><strong>Mandatos especiales para tramitar pasaportes, visas, autorizaciones de viajes de sus
        menores hijos que están en Guatemala.<br>
        </br></strong></li>
	      <li><strong>Mandatos Judiciales para llevar cualquier juicio o proceso legal en Guatemala.
          </strong></li>
        </ol>
        </p>
      </Content>

      <Services />

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default MandatosPage

